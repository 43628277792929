import { QuestionInfo } from 'app/shared/models';

export interface GridQuestionData {
  moduleName: string;
  moduleId: number;
  companyId: number;
  questionId: number;
  key: string;
  text: string[];
  indexes: { id: number, key: string, name: string }[];
  moduleOrder: number;
  excluded: boolean;
  questionPriority: number;
  pageGroupId: number;
  pageGroupName: string;
  pageGroupIcon: string;
  pageGroupIndex: number;
  moduleQuestionId?: string;
  excludedByUser?: boolean;
  hasActiveDuplicates?: boolean;
  excludedByRule?: boolean;
  excludedByRuleTarget?: boolean;
  questionType?: 'B' | 'C';
  customAdded?: boolean;
  icon?: string;
  type?: string;
  ruleTargets?: string[];
  ruleSources?: string[];
  questionText?: string;
  questionHelpText?: string;
}
export interface ValidationData {
  children: ValidationData[] | null;
  parentId: number | null;
  id: number;
  key: string;
  name: string;
  minQuestionCount: number;
  maxQuestionCount: number;
  indexAvailableQuestionsCount: number;
  additionalQuestions: QuestionInfo[];
  questionCount: number;
  includedQuestions: any[];
  required: boolean;
  status: string;
  statusIcon?: string;
  errors?: ValidationError;
}


export enum ValidationError {
  None = 'ok',
  Warning = 'warning',
  Error = 'error',
}

export interface GridQuestionDataGroup {
  moduleId: number;
  pageGroup: number;
  questions: GridQuestionData[];
  order?: number;
}
